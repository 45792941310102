<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="手机号" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输入手机号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="昵称" prop="nickName">
                <a-input v-model="queryParam.nickName" placeholder="请输入昵称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="用户类别" prop="operationType">
                <a-select v-model="queryParam.operationType" placeholder="请选择用户类别" allow-clear>
                  <a-select-option value="1">真实用户</a-select-option>
                  <a-select-option value="0">运营号</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="用户分类" prop="userType">-->
<!--                <api-select-->
<!--                  v-model="queryParam.userType"-->
<!--                  placeholder="请选择用户分类"-->
<!--                  :option="[-->
<!--                    { id: '3', name: '用户端' },-->
<!--                    { id: '4', name: '商家端' },-->
<!--                  ]" />-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="城市" prop="city">
                  <a-input v-model="queryParam.city" placeholder="请输入城市(标准行政区划编码) 具体到市" allow-clear/>
                </a-form-item>
              </a-col>
              <!--              <a-col :md="8" :sm="24">
                <a-form-item label="用户签名" prop="sign">
                  <a-input v-model="queryParam.sign" placeholder="请输入用户签名" allow-clear/>
                </a-form-item>
              </a-col>-->
              <!--              <a-col :md="8" :sm="24">
                <a-form-item label="是否官方号" prop="isOfficial">
                  <a-select  style="width: 100%"  v-model="queryParam.isOfficial" allowClear>
                    <a-select-option v-for="(item, index) in this.TFEnum" :value="item.type" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>-->
              <a-col :md="8" :sm="24">
                <a-form-item label="性别" prop="gender">
                  <a-select placeholder="请选择性别" style="width: 100%" v-model="queryParam.gender" allowClear>
                    <a-select-option v-for="(item, index) in this.customDict.GenderEnum" :value="item.type" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['user:user:export']">-->
        <!--          <a-icon type="download" />导出-->
        <!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <extra-form
        ref="extraForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="isOfficial" slot-scope="text, record">
          <!-- 重写自定义了个字典组件-->
          <custom-dict-tag :options="customDict.TFEnum" :value="record.isOfficial"/>
        </span>
        <span slot="gender" slot-scope="text, record">
          <!-- 重写自定义了个字典组件-->
          <custom-dict-tag :options="customDict.GenderEnum" :value="record.gender"/>
        </span>
        <template slot="avatar" slot-scope="text, record">
          <div>
            <img v-if="record.avatar" :src="record.avatar + '?x-oss-process=image/format,png'" style="width:60px;height:62px;" @preview="handlePreview" alt=""/>
          </div>
        </template>
        <span slot="lastLoginTime" slot-scope="text, record">
          {{ parseTime(record.lastLoginTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['user:user:edit']">
            <a-icon type="edit"/>修改
          </a>
          <a-divider type="vertical" v-hasPermi="['User:User:edit']" />
          <a @click="$refs.extraForm.handleUpdate(record, undefined)" v-hasPermi="['user:user:edit']">
            <a-icon type="edit"/>额外修改
          </a>
<!--          <a-divider type="vertical" v-hasPermi="['user:user:edit']" />-->
<!--          <a-dropdown v-hasPermi="['user:user:edit']">-->
<!--            <a class="ant-dropdown-link">-->
<!--              徽章相关<a-icon type="down" />-->
<!--            </a>-->
<!--            <a-menu slot="overlay">-->
<!--              <a-menu-item>-->
<!--                <a href="javascript:" @click="showEvaluator(record)">测评活动</a>-->
<!--              </a-menu-item>-->
<!--            </a-menu>-->
<!--          </a-dropdown>-->

          <!--          <a-divider type="vertical" v-hasPermi="['user:user:remove']" />-->
          <!--          <a @click="handleDelete(record)" v-hasPermi="['user:user:remove']">-->
          <!--            <a-icon type="delete" />删除-->
          <!--          </a>-->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />

<!--      <a-modal v-model="evaluatorVisible" title="测评活动" style="padding: 10px 20px 20px" :width="300" @ok="submitEvaluator">-->
<!--        <a-form-model-item label="测本数量" style="margin-bottom: 0">-->
<!--          <a-input-number v-model="evaluatorForm.evaluatorAmount" :min="0" :max="30" :precision="0" />-->
<!--        </a-form-model-item>-->
<!--      </a-modal>-->
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listUser, delUser } from '@/api/user/user'
// import { getUserExtra, updateUserExtra } from '@/api/user/userExtra'
import CreateForm from './modules/CreateForm'
import ExtraForm from './modules/ExtraForm'
import { mapGetters } from 'vuex'
import { tableMixin } from '@/store/table-mixin'
import CustomDictTag from '@/components/DictCustomTag'
import ApiSelect from '@/views/config/badge/modules/ApiSelect.vue'
import { parseTime } from '@/utils/ruoyi'

export default {
  name: 'User',
  components: {
    ApiSelect,
    CustomDictTag,
    CreateForm,
    ExtraForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 预览头像
      previewVisible: false,
      previewAvatar: '',
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        mobile: null,
        nickName: null,
        operationType: undefined,
        lastLoginTime: null,
        lastLoginIp: null,
        registerIp: null,
        city: null,
        sign: null,
        birthday: null,
        isOfficial: null,
        gender: null,
        avatar: null,
        integral: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        /* {
          title: 'id',
          dataIndex: 'id',
          ellipsis: false,
          align: 'center'
        }, */
        {
          title: '头像',
          dataIndex: 'avatar',
          scopedSlots: { customRender: 'avatar' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '昵称',
          dataIndex: 'nickName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          ellipsis: false,
          align: 'center'
        },

        /* {
          title: '最后登录IP',
          dataIndex: 'lastLoginIp',
          ellipsis: false,
          align: 'center'
        },
        {
          title: '注册IP',
          dataIndex: 'registerIp',
          ellipsis: false,
          align: 'center'
        }, */

        {
          title: '生日',
          dataIndex: 'birthday',
          ellipsis: false,
          align: 'center'
        },
        {
          title: '是否官方号',
          dataIndex: 'isOfficial',
          ellipsis: true,
          scopedSlots: { customRender: 'isOfficial' },
          align: 'center'
        },
        {
          title: '性别',
          dataIndex: 'gender',
          ellipsis: true,
          scopedSlots: { customRender: 'gender' },
          align: 'center'
        },
        {
          title: '积分',
          dataIndex: 'integral',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '城市',
          dataIndex: 'city',
          ellipsis: false,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          width: 180,
          ellipsis: false,
          align: 'center'
        },
        // {
        //   title: '最后登录时间',
        //   dataIndex: 'lastLoginTime',
        //   scopedSlots: { customRender: 'lastLoginTime' },
        //   ellipsis: false,
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '13%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      evaluatorVisible: false,
      evaluatorForm: {
        id: null,
        evaluatorAmount: 0,
      },
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    parseTime,
    /** 查询用户信息列表 */
    getList () {
      this.loading = true
      listUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    handleCancel() {
      this.previewVisible = false
    },
    handlePreview(file) {
      console.log('打开了')
      this.previewAvatar = file.url || file.preview
      this.previewVisible = true
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        mobile: undefined,
        nickName: undefined,
        operationType: undefined,
        lastLoginTime: undefined,
        lastLoginIp: undefined,
        registerIp: undefined,
        city: undefined,
        isPersonal: undefined,
        cameraPermissions: undefined,
        locationPermissions: undefined,
        messageNotification: undefined,
        sign: undefined,
        birthday: undefined,
        isOfficial: undefined,
        gender: undefined,
        avatar: undefined,
        password: undefined,
        salt: undefined,
        integral: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      const that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          return delUser(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      const that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/user/export', {
            ...that.queryParam
          }, `user_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    // showEvaluator (row) {
    //   getUserExtra(row.id).then(res => {
    //     if (res.success) {
    //       this.evaluatorForm.id = res.data.id
    //       this.evaluatorForm.evaluatorAmount = res.data.evaluatorAmount
    //       this.evaluatorVisible = true
    //     }
    //   }).catch(err => {
    //     this.$message.error(err.message)
    //   })
    // },
    // submitEvaluator () {
    //   updateUserExtra(this.evaluatorForm).then(res => {
    //     if (res.success) {
    //       this.$message.success("设置测评活动成功")
    //     }
    //   }).catch(err => {
    //     this.$message.error(err.message)
    //   })
    // }
  }
}
</script>
